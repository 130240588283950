<template>
  <div>
    <ValidationObserver v-slot="context">
      <form @submit.prevent="submit(context)">
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>Enable *</span>
            </FormLabel>
            <FormContent class="flex flex-col">
              <vs-switch 
                v-model="formData.active" 
                class="mt-2" />
              <span class="text-gray pt-1">ใช้สำหรับเปิดปิดการใช้าน redirection</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Source URL *</span>
            </FormLabel>
            <FormContent class="flex flex-col">
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Source URL" 
                rules="required">
                <vs-input 
                  v-model="formData.source" 
                  placeholder="^/condo, ^/home"
                  class="w-full" 
                  name="source" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
              <span class="text-gray pt-1">กรอก Regular Expressions ที่ต้องการใช้สำหรับเช็ค path ต้นทางเพื่อทำการ redirect</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Target URL *</span>
            </FormLabel>
            <FormContent class="flex flex-col">
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Target URL" 
                rules="required">
                <vs-input 
                  v-model="formData.target" 
                  placeholder="/th/singlehome, https://lh.co.th/th/singlehome "
                  class="w-full" 
                  name="target" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
              <span class="text-gray pt-1">กรอก path หรือ url ปลายทาง</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Status Code *</span>
              <br >
            </FormLabel>
            <FormContent class="flex flex-col">
              <ValidationProvider
                v-slot="{ errors }"
                name="HTTP Status Code"
                rules="required|numeric"
              >
                <vs-input 
                  v-model="formData.status_code" 
                  placeholder="301, 302"
                  class="w-full" 
                  name="status_code" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
              <span class="text-gray pt-1">กรอก http status สำหรับการ redirect โดย ค่าตั้งต้นจะเป็น 301</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Priority *</span>
            </FormLabel>
            <FormContent class="flex flex-col">
              <ValidationProvider 
                v-slot="{ errors }" 
                name="Priority"
                rules="required">
                <vs-input 
                  v-model="formData.priority" 
                  placeholder="0"
                  class="w-full" 
                  name="Priority" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">{{ errors[0] }}</span>
              </ValidationProvider>
              <span class="text-gray pt-1">กำหนด priority ของการทำ redirection</span>
              <span class="text-gray pt-1">โดยกรณีที่ source มีค่าเท่ากันจะดูว่าการตั้งค่าไหน priority เลขต่ำกว่าจะใช้การ redirect ของการตั้งค่านั้น</span>
              <span class="text-gray pt-1">Priority มากที่สุด คือ 0 รองลงมาเป็น 1, 2, 3 ตามลำดับ</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Start Date</span>
              <br >
            </FormLabel>
            <FormContent class="flex flex-col">
              <div class="flex flex-col md:flex-row md:-mx-2">
                <div class="w-full mb-2 md:w-4/12 md:px-2">
                  <datepicker
                    v-model="formData.start_date"
                    :minimum-view="'day'"
                    :maximum-view="'year'"
                    :disabled-dates="maxStartDate"
                    format="dd MMMM yyyy"
                    placeholder="วันเริ่มต้น"
                    required
                    typeable
                    clear-button
                  />
                </div>
              </div>
              <span class="text-gray pt-1">กำหนดวันเริ่มต้นการใช้งาน redirect</span>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>Expiry Date</span>
              <br >
            </FormLabel>
            <FormContent class="flex flex-col">
              <div class="flex flex-col md:flex-row md:-mx-2">
                <div class="w-full mb-2 md:w-4/12 md:px-2">
                  <datepicker
                    v-model="formData.expiry"
                    :minimum-view="'day'"
                    :maximum-view="'year'"
                    :disabled-dates="minEndDate"
                    format="dd MMMM yyyy"
                    placeholder="วันหมดอายุ"
                    required
                    typeable
                    clear-button
                  />
                </div>
              </div>
              <span class="text-gray pt-1">กำหนดวันหมดอายุของการตั้งค่า หากการตั้งค่าหมดอายุไปแล้วจะไม่มีเกิดการ redirect ที่หน้า website</span>
            </FormContent>
          </FormLayout>
        </vx-card>
        <vx-card class="mb-base">
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel/>
            <FormContent>
              <vs-button 
                class="w-1/3 mr-4" 
                @click="submit(context)">Submit</vs-button>
            </FormContent>
          </FormLayout>
        </vx-card>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import useDatepicker from '@/use/useDatepicker'
import useRedirectionUtil from '@/use/useRedirectionUtil'
import useCrud from '@/use/useCrud'
import useNotify from '@/use/useNotify'
import moment from 'moment'
import get from 'lodash/get'
import omit from 'lodash/omit'
// import webUrlRegexer from '@/plugins/urlRegexer'

export default {
  name: 'RedirectionForm',
  components: {
    Datepicker,
    ValidationObserver,
  },
  setup(props, ctx) {
    const { success: notifySuccess, error: notifyError } = useNotify(ctx)
    const {
      root: { $store, $router, $vs },
    } = ctx
    const { initialData, formData, fetchByRouteId } = useRedirectionUtil(ctx)
    const crudForm = useCrud(ctx, 'redirection')
    crudForm.routePrefix.value = 'redirection'

    onMounted(() => {
      fetchByRouteId()
    })

    const validateWebUrlPattern = (input) => {
      let url = input
      if (url) {
        url = url.indexOf('^') === 0 ? url.slice(1) : url
      }
      const regexWebUrl = new RegExp(
        "^" +
        // protocol identifier (optional)
        // short syntax // still required
        "(?:(?:(?:https?|ftp):)?\\/\\/)" +
        // user:pass BasicAuth (optional)
        "(?:\\S+(?::\\S*)?@)?" +
        "(?:" +
        // IP address exclusion
        // private & local networks
        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
        // IP address dotted notation octets
        // excludes loopback network 0.0.0.0
        // excludes reserved space >= 224.0.0.0
        // excludes network & broadcast addresses
        // (first & last IP address of each class)
        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
        "|" +
        // host & domain names, may end with dot
        // can be replaced by a shortest alternative
        // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
        "(?:" +
        "(?:" +
        "[a-z0-9\\u00a1-\\uffff]" +
        "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
        ")?" +
        "[a-z0-9\\u00a1-\\uffff]\\." +
        ")+" +
        // TLD identifier name, may end with dot
        "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
        ")" +
        // port number (optional)
        "(?::\\d{2,5})?" +
        // resource path (optional)
        "(?:[/?#]\\S*)?" +
        "$", "i"
      )

      const regexWebPathUrl = new RegExp(/^\/([^?/]+)/)

      // console.log(`result web url pattern: ${regexWebUrl.test(input)}`)
      // console.log(`result web url path pattern: ${regexWebPathUrl.test(input)}`)
      
      return regexWebUrl.test(url) || regexWebPathUrl.test(url)
    }

    const updateItem = ({ validate }) => {
      validate().then((result) => {
        if (!result) {
          notifyError({
            text: 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง'
          })
          return
        }
        $vs.loading()

        const activeUser = ctx.root.$store.state.AppActiveUser
        const email = get(activeUser, 'email', '')
        let created_by = formData.created_by
        const updated_by = email

        // add user created by
        if (!created_by) {
          created_by = email
        }

        $store
          .dispatch('redirection/updateItem', { initialData,
            formData: {
              ...omit(formData, ['updated_at']),
              created_by,
              updated_by
            }
          })
          .then(() => {
            $router.push({ name: 'redirection' })
            $vs.loading.close()
            notifySuccess({ text: `${formData.id ? 'แก้ไข' : 'เพิ่ม'}ข้อมูลเรียบร้อย` })
          })
          .catch((error) => {
            $vs.loading.close()
            notifyError({ text: JSON.stringify(error) })
          })
      })
    }

    const submit = (ctx) => {
      if (!validateWebUrlPattern(formData.source)) {
        notifyError({ text: JSON.stringify('รูปแบบ URL path: Source ไม่ถูกต้อง') })
        return false 
      }

      if (!validateWebUrlPattern(formData.target)) {
        notifyError({ text: JSON.stringify('รูปแบบ URL path: Target ไม่ถูกต้อง') })
        return false
      }


      if (formData.id) {
        updateItem(ctx)
      } else {
        crudForm.addItem(ctx, {
          ...formData,
        })
      }
    }

    const minEndDate = computed(() => {
      const min = moment(formData.start_date).format()
      return { to: new Date(min) }
    })

    const maxStartDate = computed(() => {
      const max = moment(formData.expiry).format()
      
      return { from: new Date(max) }
    })

    watch(
      () => formData.source,
      (newValue) => {
        if (newValue) {
          const decodeUrl = decodeURIComponent(newValue)
          if (decodeUrl !== formData.source) {
            formData.source = decodeUrl
          }
        }
      }
    )

    watch(
      () => formData.target,
      (newValue) => {
        if (newValue) {
          const decodeUrl = decodeURIComponent(newValue)
          if (decodeUrl !== formData.target) {
            formData.target = decodeUrl
          }
        }
      }
    )

    return {
      ...crudForm,
      updateItem,
      formData,
      submit,
      useDatepicker: useDatepicker(),
      minEndDate,
      maxStartDate
    }
  },
}
</script>
